:root {
  --navy-blue: #00305f;
  --white: #ffffff;
  --navy-blue-hover: #044789;
  --tab-background: #0561b7;
  --light-green: RGB(28 200 138);
}
//$base-color: #c6538c;
$border-black: #000000;
$trash-red: #ff0000;
$common-color: #00b38c;

.sticky-header {
  position: fixed;
  width: 100%;
  z-index: 1033;
}

.profile-icon-area {
  padding-left: 20px;
  line-height: 1.2 !important;
  margin-right: 0.5rem !important;
}

.doctor-name,
.doctor-speciality,
.doctor-organization {
  display: block;
}

.doctor-name {
  color: #000000 !important;
  font-size: 15px !important;
}

.doctor-speciality {
  color: rgb(20, 158, 39) !important;
  font-size: 14px !important;
}

.doctor-organization {
  color: #0d6efd !important;
  font-size: 14px;
}

.logo-img {
  height: 50px;
  width: 220px;
}

.img-profile {
  height: 3.1rem !important;
  width: 3.1rem !important;
}

.supported-by {
  margin-left: 3rem !important;
  font-style: italic;
  padding-top: 20px;
  line-height: 14px;
}

.take-note,
.print-preview {
  padding-right: 10px;
  font-size: 14px;
}

.bcolor {
  color: #000000;
}

.search-field,
.search-btn {
  background: #fff !important;
}

.search-btn {
  border-left: 1px solid #1d1d1d;
}

.file-upload {
  font-size: 22px;
  padding-left: 15px;
}

.prescription-top-search {
  border-radius: 6px;
}

.chief-complain-item {
  position: relative;
}

.prescription-item {
  color: #000000;
  font-size: 16px;
}

.footer-area {
  position: fixed;
  padding: 10px 0px 0px 42px;
  bottom: 19px;
  margin-bottom: 40px;
  width: 96%;
  margin-left: 2.8rem;
  /* Height of the footer*/
  height: auto;
  /* background: #ffffff; */
}

.footer {
  margin-left: 10px;
  margin-right: 10px;
}

.sticky-footer {
  position: fixed;
  padding: 7px 0 !important;
  bottom: 0;
  width: 100%;
  /* Height of the footer*/
  /* height: 40px; */
  background: grey;
}

.new-rx-btn {
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
  padding: 0 6px;
  position: absolute;
  right: 66px;
  top: 9px;
  background: #00b38c !important;
  color: #fff;
  i {
    margin-left: -5px;
    font-size: 14px;
  }
  &:hover {
    border: 2px solid green;
    border-radius: 10px;
  }
}

.header-sticky-second {
  margin-top: 70px;
  // margin-left: -10px;
  background-color: RGB(228 229 232);
  height: 50px;
  width: 100%;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid RGB(231, 231, 231);
  z-index: 555;
}

.print-card-body {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}

.card-body {
  padding: 0;
}

.rx-body {
  margin-top: 7.475rem;
  margin-left: 2rem;
  min-height: 84vh;
}

.no-appointment {
  .rx-body {
    margin-left: 0 !important;
  }
  .container-fluid {
    padding-left: 0.9rem;
  }
}

.card-content {
  margin-top: 6rem;
  margin-left: 3.5rem;
  min-height: 84vh;
}

.fa-trash {
  color: $trash-red;
}

.btn-primary {
  border-color: $common-color !important;
  background-color: $common-color !important;
}

.common-modal-header {
  background-color: #f8f8f8;
  height: 46px;
  align-items: center;
  color: #0561b7;
  font-family: 'HelveticaNeue';
}

.medicine-default .common-modal-header {
  height: 32px;
}

.common-modal-header .modal-title {
  font-weight: 600 !important;
  font-size: 1.4rem;
}

.modal-title {
  font-weight: 600 !important;
  font-size: 1.2rem;
  color: #0561b7;
}

.rx-type-btns > button {
  padding: 0.01rem 0.7rem !important;
  border-radius: 5px !important;
  line-height: 0.7rem !important;
  font-size: 14px !important;
  height: 26px;
}

.rx-type-btns .fa-times-circle {
  margin-left: 1.3rem;
}

.common-modal-header .btn-sm {
  padding: 0.1rem 0.5rem !important;
}

.nav-pills .nav-link {
  cursor: pointer;
}

.nav-pills .nav-link.active {
  background-color: $common-color !important;
  cursor: pointer;
}

.tab-content {
  background-color: #f5f5f5;
}

.nav-bar-top {
  align-items: center;
  .no-internet {
    color: $trash-red;
  }
}
.search-field {
  padding-left: 35px;
  border: 1px solid #c0c0c0;
}

.custom-tab {
  font-size: 16px;
  background: #f1f8ff;
  border: 0.5px solid $common-color !important;
  border-radius: 8px;
  margin: 10px 0;
  text-align: center;
  .nav-link {
    display: block;
    padding: 0.1rem 1rem;
  }
  a {
    color: #7a7777;
  }
  .active {
    color: #fff !important;
  }
  .fa-save {
    color: $common-color;
    padding-top: 5px;
    cursor: pointer;
  }
  .save-btn-area {
    text-align: end;
    padding-right: 12px;
    width: 55px;
  }
}

.gyne-tab {
  font-size: 15px !important;
}

.load-more-icon {
  font-size: 19px;
  padding-right: 13px;
  color: $common-color;
}

.search-icon {
  position: absolute;
  top: 0.7rem;
  left: 1.5rem;
}

.label-custom {
  margin-bottom: 0 !important;
  padding-left: 2px;
  font-size: 15px;
}

.move-to-history-btn {
  color: rgb(5, 97, 183);
  font-weight: normal;
  font-size: 14px;
  font-style: italic;
  cursor: pointer;
}

.saved-image-over img {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 444;
}

.nav-profile-menu-area {
  .online {
    border: 2px solid #2ae643;
  }
  .offline {
    border: 2px solid #cd1010;
  }
  .icon {
    position: absolute;
    left: 45px;
    top: 47px;
    height: 13px;
    width: 13px;
    border-radius: 50%;
  }
  .active-icon {
    background: #2ae643;
  }
  .inactive-icon {
    background: #cd1010;
  }
}

// .sync-btn {
//   margin-right: 100px;
//   .online {
//     border: 2px solid #2ae643;
//     color: #2ae643;
//   }
//   .offline {
//     border: 2px solid #cd1010;
//     color: #cd1010;
//   }
//   i {
//     padding-top: 22%;
//     padding-left: 22%;
//     font-size: large;
//     &:hover {
//       font-size: larger;
//     }
//   }
// }

.sync-btn {
  width: auto;
  .sync-circle {
    height: 2.1rem !important;
    width: 2.1rem !important;
  }
  //margin-right: 100px;
  .online {
    border: 2px solid #2ae643;
    color: #2ae643;
  }
  .offline {
    border: 2px solid #cd1010;
    color: #cd1010;
  }
  i {
    padding-top: 21%;
    padding-left: 20%;
    font-size: medium;
    &:hover {
      font-size: large;
    }
  }
}

.is-syncing {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.loader-sync {
  display: flex;
  width: 37px;
  height: 37px;
  top: 15px;
  margin-right: 7px;
  margin-top: 9px;
}

.prescription-signature-view {
  margin-top: 1.5rem !important;
  margin-bottom: 3rem !important;
  margin-right: 3rem !important;
  float: right;
  .signature-image {
    padding-bottom: 6px;
  }
  .doctor-name {
    border-top: 1px dotted #1d1d1d;
    span {
      text-align: center;
      padding-top: 6px;
    }
  }
}

.signature-fixed {
  position: static;
}

.normal-card-body {
  // display: none !important;
}

.weight-patient-info .unit-btn .btn-sm {
  padding: 0.15rem 0.2rem !important;
}

.patient-info .react-datepicker-wrapper {
  width: 100%;
}

.unit-btn {
  display: flex;
  flex-wrap: wrap;
  .btn-sm-customize {
    line-height: 1rem !important;
    min-width: 29px;
    height: 26px;
    font-size: 0.85rem !important;
    background: azure;
    color: #000;
    box-shadow: RGB(14 30 37/12%) 0px 2px 4px 0px,
      RGB(14 30 37/32%) 0px 2px 16px 0px;
    padding: 0.25rem 0.32rem !important;
  }
  .empty-btn {
    background-color: #e7f3f2 !important;
  }
}

.customize-btn {
  padding: 0.07rem 0.2rem !important;
  line-height: 1.5 !important;
}

.normal-card-body {
  .drag-drop {
    .fa-times-circle {
      padding-right: 7px !important;
    }
    li {
      div:hover {
        background: #eaf8ff;
      }
    }
    .follow-up-list:hover {
      background: #eaf8ff;
    }
  }
  .prescription-body-left {
    .sub-child-list {
      width: 94%;
    }
  }
  .prescription-body-right {
    .sub-child-list {
      //width: 94%;
      width: 100%;
    }
    .referred-to-view-list {
      .fa-times-circle {
        padding-right: 52px !important;
      }
    }
    .history-item {
      .fa-times-circle {
        padding-right: 3.3rem !important;
      }
    }
  }
}

.advice-ul {
  width: 96% !important;
  padding-left: 0 !important;
  padding-right: 5px !important;
}
.rehab-ul {
  width: 100% !important;
}
.follow-ul {
  width: 96% !important;
}
.advice-item {
  .fa-times-circle {
    padding-right: 0 !important;
  }
}

.sub-child-list ul li {
  list-style-type: disc;
}

.advice-title {
  font-weight: bold;
}

.advice-item {
  margin-left: 1.8rem;
  ul {
    padding-left: 1.7rem;
  }
}

.for-help {
  font-style: italic;
  float: right;
  span {
    color: #cd1010;
    font-weight: bold;
    font-style: italic;
  }
  i {
    font-size: 10px;
    margin-left: 0.5rem !important;
    margin-right: 0.3rem !important;
    color: #000000;
  }
}

.field-type-group-btn {
  float: right !important;
  .btn-outline-secondary {
    color: #014081 !important;
  }
}

.field-type-group-btn > button {
  min-width: 40px !important;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 12px;
  color: #ffffff;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

.new-edit-icon {
  width: 23px;
  height: 25px;
  padding-top: 4px;
}

.full-width-btn {
  width: 100%;
}

.field-type-group-btn {
  float: right !important;
  .btn-outline-secondary {
    color: #014081 !important;
  }
}

.field-type-group-btn > button {
  min-width: 40px !important;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 12px;
  color: #ffffff;
}

.on-examination-view-area {
  width: 100%;
  font-size: 15px;
  //padding-left: 8px;
}

.on-examination-view-area,
.history-view {
  ul {
    padding-left: 1.7rem;
    margin-bottom: 0;
  }
}
//////////*****dental Examination*****//////////

.teeth-area {
  position: relative;
  height: 133px;
}

.hr-dental {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  border: 0.5px solid $border-black;
  width: 78%;
}

.hr-dental-bottom {
  width: 53% !important;
}

.hr-dental-figure {
  width: 53% !important;
}

.horizontal-border-row {
  border: 1px solid;
  position: absolute;
  width: 343px;
  text-align: center;
  top: 48%;
  left: 212px;
}

.vertical-border-row {
  border: 1px solid;
  position: absolute;
  text-align: center;
  top: 14px;
  left: 383px;
  bottom: 25px;
}

////////////////////*** PATIENT SECTION ***///////////////////
.patient-section {
  height: 40px;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-right: none;
  align-items: center;
  margin: 0 18px;
  justify-content: space-between;
  color: #000000 !important;
  font-weight: bold;

  .form-group {
    padding-top: 0;
    margin-bottom: 0;
  }
  .age-input-group > button {
    max-width: 50px !important;
  }
  .age-input-group {
    .form-control-sm {
      padding-right: 0 !important;
      max-width: 32px !important;
    }
    span {
      font-size: 13px;
      padding-top: 8px;
      color: #6e707e;
      font-weight: normal !important;
    }
  }

  .name {
    width: 250px;
  }
  .phone-number {
    width: 160px;
  }
  .add-more-info {
    font-size: 13px;
    padding: 2px 4px;
    margin-right: 8px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #0561b7 !important;
  }

  .add-more-info:hover {
    background-color: #d7d7d7cf;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .form-control {
    border: 0;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input[type='date']:focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
  .placeholder {
    font-size: 13px;
    font-style: italic;
  }
}

.prescription-item:hover {
  background-color: #d7d7d7cf;
}

////////////////////*** PATIENT SECTION ***///////////////////

.dental-figure-view {
  height: 90px;
  width: 280px;
  margin-left: 30px;
  margin-top: 10px;
  position: relative;

  .hr-line-dental {
    border: 0.5px solid $border-black;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
  }

  .vl-line-dental {
    border: 0.5px solid $border-black;
    position: absolute;
    text-align: center;
    left: 50%;
    height: 100%;
    top: 0;
  }

  .top-row {
    padding-top: 12px;
    div:first-child {
      display: inline-block;
      width: 50%;
      text-align: right;
      padding-right: 10px;
    }

    div:last-child {
      display: inline-block;
      width: 50%;
      text-align: left;
      padding-left: 10px;
    }
  }
  .bottom-row {
    padding-top: 20px;
    div:first-child {
      display: inline-block;
      width: 50%;
      text-align: right;
      padding-right: 10px;
    }

    div:last-child {
      display: inline-block;
      width: 50%;
      text-align: left;
      padding-left: 10px;
    }
  }
}

.dental-examination {
  position: relative;
}

.vertical-line {
  border: 0.5px solid $border-black;
  height: 125px;
  position: absolute;
  left: 50%;
  top: 5px;
}

.vertical-line-figure {
  height: 130px !important;
  top: 221px;
}

.title-gyne-examination {
  font-weight: 800 !important;
  // padding-left: 10px;
  padding-top: 6px;
}

.center {
  display: flex;
  justify-content: center;
}
.statistics-block,
.color-today {
  height: 6rem;
  width: 90%;
  margin-top: 1.5rem;
  border-radius: 0.4rem;

  .stat-icon {
    color: white;
    opacity: 0.3;
    margin: 0.9rem 0.4rem;
  }

  .stat-number {
    margin-top: 0.8rem;
    color: white;
    font-size: 2rem;
    display: block;
    text-align: center;
  }
  .stat-time {
    display: block;
    color: white;
    opacity: 0.4;
    font-weight: bold;
    font-style: italic;
    font-size: 1.1rem;
  }
}
.color-today {
  border: 1px solid #00a1b5;
  background-color: #00a1b5;
}
.color-yesterday {
  border: 1px solid #00a1b5;
  background-color: #00a1b5;
}
.color-last-7-days {
  border: 1px solid #009487;
  background-color: #009487;
}
.color-last-30-days {
  border: 1px solid #5eb86f;
  background-color: #5eb86f;
}
.color-last-total-patient {
  border: 1px solid #9d9d9d;
  background-color: #9d9d9d;
}
.black {
  color: #000000;
}
.pie-chart-box {
  border: 1px solid lightgrey;
  height: auto;
  text-align: center;
  border-radius: 0.3rem;
  margin: 0 auto;
  width: 14rem;

  .header {
    margin-top: 0.6rem;
  }
  .horizontal-bar {
    display: inline-block;
    width: 2.4rem;
    height: 0.6rem;
    margin-left: 0.5rem;
    margin-right: 0.2rem;
  }
  span {
    font-size: 0.8rem;
  }
}

.light-purple {
  background-color: #ffa500;
}
.light-green {
  background-color: #a4c639;
}
.dark-purple {
  background-color: #6638e2;
}
.light-red {
  background-color: #f16096;
}
.light-blue {
  background-color: #004bd2;
}

//////////*****systemic Examination*****//////////

.systemic-examination {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
  .accordion {
    padding: 3px 8px;
  }
  .accordion-collapse {
    padding-left: 0;
    button {
      font-weight: bold !important;
    }
  }
  .accordion-header {
    margin: 6px 0;
  }
  .jvp-area {
    padding-left: 25px;
    padding-right: 10px;
  }
  .accordion-button {
    font-size: 16px !important;
    font-weight: bold;
    padding: 5px 0 3px 15px;
  }
  .row {
    align-items: center;
  }
  .csv-sub-title {
    font-size: 17px;
    font-weight: bolder;
  }
  .row-title {
    font-size: 14px;
    font-weight: bold;
  }
}

.systemic-examination-view {
  i {
    float: right;
    padding-right: 3rem !important;
  }
  .coronary,
  .angiogram {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.oncology-examination-view {
  i {
    float: right;
    padding-right: 3rem !important;
  }
  font-size: 14px;
}

.on-examination .oncology-examination-view {
  padding-left: 10px;
}

//////////*****oncology Examination*****//////////

.oncology-examination {
  height: 452px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px 12px 12px 30px;

  .label-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5289px;
  }

  .cns-ipi-right-part {
    margin-top: 8px;
    .first,
    .second,
    .third {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 12px;
      border-radius: 8px;
      background-color: #eaeaea;
      margin-bottom: 13px;
      height: 68px;
      .point-area {
        display: flex;
        align-items: center;
        font-weight: 900 !important;
        span:first-child {
          color: #014081;
          font-size: 32px !important;
        }
        span:last-child {
          padding-left: 8px;
        }
      }
      .risk-status {
        width: 55%;
        span {
          display: block;
        }
      }
    }
  }

  .right-part-ag-cal {
    width: 99%;
    height: 78%;
    background-color: #eaeaea;
    padding-top: 40%;
    border-radius: 8px;
    text-align: center;
    span {
      display: block;
      font-weight: 700;
      font-size: 32px;
      color: #014081;
    }
    p {
      padding-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 12.68px;
    }
  }

  .mm-iss-right-part {
    width: 100%;
    height: 73%;
    padding-top: 15px;
    padding-left: 15px;
    border-radius: 8px;
    background-color: #eaeaea;
    margin-bottom: 0.5rem !important;

    div:first-child {
      color: #014081;
      font-size: 24px;
    }
    div:last-child {
      font-size: 14px;
    }
  }

  .oncology-btn-group {
    width: 100%;
    .btn-outline-secondary {
      color: #014081 !important;
    }
  }

  .oncology-btn-group > button {
    width: auto;
    font-size: 13px !important;
    font-weight: 400;
    // line-height: 12px;
    color: #ffffff;
  }

  .btn-full {
    width: 100%;
    margin: 3px 0;
  }

  .cns-btn-text {
    span:first-child {
      float: left;
    }
    span:last-child {
      float: right;
    }
  }

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid black;
  }

  // .btn-primary {
  //   background-color: #014081 !important;
  // }

  p {
    font-weight: 400;
    font-size: 8px;
    line-height: 12.3px;
  }
}

.title {
  font-weight: 600;
}

.staging {
  padding: 10px;

  hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-top: 1px solid black;
  }

  .has-search .form-control {
    padding-left: 2.375rem;
    line-height: 2.5;
  }

  .has-search .form-control-feedback {
    position: absolute;
    // z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.075rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }
}

#react-select-4-listbox {
  z-index: 5 !important;
}
.css-1t8y0t9-control {
  min-height: 33px !important;
}

.breast-examination {
  .row {
    margin-bottom: 10px;
  }
}
.gyne-examination,
.breast-examination {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
  }
}
.gyne-examination {
  padding: 8px;
  .field-type-group-btn {
    padding-top: 3px;
    float: left !important;
  }

  .field-type-group-btn > button {
    min-width: 50px !important;
  }

  // .gyne-common-btn > button {
  //   width: 25px;
  // }
  .gyne-common-btn {
    button {
      width: 25px;
    }
    margin-left: 10px;
  }
  .row {
    padding-left: 8px;
    font-size: 15px;
  }
}

.breast-exam-view {
  i {
    float: right;
    padding-right: 3rem !important;
  }
  .breast {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .gyne-view-title {
    font-size: 13px;
    font-weight: bold;
  }
}

.gynocology-history {
  padding-left: 8px;
  padding-right: 8px;
  .mp-mc {
    padding-top: 7px;
    text-align: left;
    div:first-child {
      border-bottom: 1px solid $border-black;
      padding-bottom: 8px;
      margin-bottom: 8px;
      width: 21px;
    }
    div:last-child {
    }
  }
  .mp-mc-right {
    padding-top: 26px;
    font-weight: bold;
  }
  .border-mp-mc {
    border-bottom: 1px solid $border-black;
    margin: 5px 2px;
  }
  .gravida-btn {
    margin-top: 0 !important;
  }
  .para-input {
    display: inline-block;
    width: 118px;
  }
  .btn-group-vertical {
    button {
      height: 15px;
      padding: 0 0.22rem;
    }
    i {
      font-size: 10px;
    }
  }
}

.surgical-history {
  padding-left: 8px;
  padding-right: 8px;
  .row {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

.infertility-view {
  .title {
    padding-left: 0.5rem;
    font-size: 15px;
  }
  .fa-times-circle {
    float: right;
    padding-right: 3rem !important;
  }
  .separator {
    display: inline-block;
    width: 160px;
    margin-left: -14px;
    border-bottom: 1px solid #6e707e;
  }
  .border-right {
    border-right: 1px solid #6e707e;
  }
}

// .prescription-history {
//   .infertility-view {
//     padding-left: 10px;
//     .border-right {
//       margin-right: 10px;
//     }
//   }
// }

#accordionSidebar {
  position: fixed;
  align-items: center;
  margin-top: 121px;
  transition: all 0.3s ease-in-out 0s;
  // z-index: 1055;
}

.sidebar {
  width: 8.5rem !important;
}

.sidebar.toggled .nav-item .nav-link {
  width: 2.5rem !important;
  padding: 1rem;
}

.sidebar #sidebarToggle {
  width: 1.8rem;
  height: 1.8rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-left: 10px;
}

.sidebar.toggled {
  width: 4.7rem !important;
}

.navbar-toggler {
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  padding: 1rem;
  z-index: 200;
  text-align: right;
}

.template-search {
  width: 342px;
  .row .col {
    padding-right: 0 !important;
  }
}

// .selected-template {
//   color: rgb(0, 128, 0);
//   font-weight: bold;
//   background-color: #004bd2;
// }

.avatar-parent {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  margin-top: 9px;
  .avatar-child {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    padding-top: 9px;
    margin-left: -7px;
    background: #ddd;
    text-align: center;
    &:first-child {
      margin-top: -6px !important;
    }
  }
  .waiting-patient {
    z-index: 1;
    .more-btn {
      font-size: 17px;
      font-weight: 700;
      i {
        font-size: 14px;
      }
    }
  }
}

.header-sticky-second {
  display: flex;
  justify-content: space-between;
  .left-side-pres {
    width: 500px;
    display: flex;
    border-radius: 40px 999em 999em 40px;
    align-items: center;
    font-size: 19px;
    white-space: nowrap;
    margin-left: -3.8rem;
    background: $common-color;
    .nav-link {
      width: 56px;
      color: #ffffff;
    }
    .active {
      background: #006b54;
      font-size: 21px;
      text-align: center;
    }
  }
  .right-side-pres {
    display: flex;
    align-items: center;
    justify-content: end;
    white-space: nowrap;
    // margin-top: 5.313rem;
    // padding-right: 2.2rem;
    margin-right: 36px;
  }

  .right-side-pres > div:not(:last-child) {
    margin-right: 20px;
  }
}
.no-appointment .right-side-pres {
  margin-right: 26px !important;
}

.onexamination-modal {
  .general-examination {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    .row {
      align-items: center;
    }
    .form-control-sm {
      font-size: 0.775rem !important;
      margin-top: 5px;
    }
    .btn {
      font-size: 12px !important;
      padding: 0.21rem 0.36rem !important;
    }
  }

  .ocular-examination {
    font-size: 14px !important;
    padding-left: 14px !important;
    padding-top: 12px !important;
    color: #000;
  }

  .ocular-examination .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.settings-rx-item {
  padding: 4px 5px;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    background-color: #d7d7d7cf;
    border-radius: 4px;
  }
}

.subitem-dropdown .suggestionTitle {
  margin-top: -8px;
  background-color: #00b38c;
  font-weight: bold;
  color: white;
  cursor: default;
  padding-left: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.suggestionTitleRx {
  width: 20.68rem !important;
}

.subitem-dropdown {
  display: 'inline-block';
}
.subitem-dropdown .suggestionItem {
  padding-left: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.subitem-dropdown .suggestionItem:hover {
  background-color: lightgrey;
}

.subitem-dropdown .subitems {
  height: auto;
  max-height: 150px;
  overflow: auto;
  border: 1px solid #00b38c;
  width: 250px;
  padding-bottom: -8px;
}

.subitems-rx {
  width: 500px !important;
  /* width: 20.68rem !important */
}

.search-suggestion-rx .subitems-rx {
  width: 20.68rem !important;
}

.subitem-dropdown .dropdown-menu {
  padding: 0px;
}

.suggestion-error {
  color: red;
  padding-left: 20px;
}

.search-suggestion {
  height: auto;
  width: auto;
  background-color: white;
  position: absolute;
  //right: -250px;
  right: -37px;
  /* border: 1px solid lightgrey; */
  animation: fadeIn 0.6s;
}
.search-suggestion-rx {
  /* right: -450px; */
  //right: -20.68rem !important;
  right: -8rem !important;
}

.schedule-rx {
  width: 7.8125rem;
  padding-left: 3px;
}
.selected-default-row-rx,
.selected-default-row-rx:hover {
  background-color: #008000;
  color: white;
  cursor: pointer;
}

.medicine-type {
  display: block;
  text-align: left;
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 12px;
  font-size: 10px;
  color: var(--navy-blue);
}

// .medicine-name {
//   display: block;
//   font-weight: bold;
//   line-height: 12px;
// }

.medicine-name {
  font-size: 14px;
  font-weight: bold;
  line-height: 12px;
  color: #000;
}

.medicine-company {
  font-size: 10px;
  line-height: 10px;
  color: var(--navy-blue);
}

.make-default-btn-rx {
  cursor: pointer;
  padding: 2px 4px;
  color: #0d6efd !important;
}
.make-default-btn-rx:hover {
  font-weight: bold;
  cursor: pointer;
}
.selected-default-rx {
  color: red;
  cursor: pointer;
  padding: 2px 4px;
}

span.rx-width {
  width: 300px;
}

span.rx-white {
  background-color: white;
}

.bottom-border {
  border-bottom: 1px solid darkgrey;
  width: 90%;
}

.rx-left-space {
  margin-left: 2.5rem !important;
}

span.rx-width {
  width: 300px;
}

span.rx-white {
  background-color: white;
}

.bottom-border {
  border-bottom: 1px solid darkgrey;
  width: 90%;
}

.rx-left-space {
  margin-left: 2.5rem !important;
}

.history-view {
  padding-left: 7px;
  .drug-view {
    font-size: 15px !important;
  }
  .his-sub-title {
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
  }
}

.systemic-examination-view {
  .his-sub-title {
    padding-left: 8px;
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
  }
}
.breast-exam-view {
  .sub-title {
    padding-left: 8px;
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
  }
}

.medicine-remarks .rbt {
  position: static !important;
}

.medicine-default {
  .modal-body {
    padding: 0 4px;
  }
  .modal-md {
    margin-top: 100px;
  }
  .nav-item {
    font-size: 11px;
  }
  .similar-medicine {
    .list {
      div {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        padding: 0 12px 2px 9px;
        span:first-child {
          width: 48%;
        }
        span:last-child {
          font-style: italic;
          width: 52%;
          text-align: right;
        }
      }
      .selected-similar-medicine {
        color: #00305f;
        font-weight: bolder;
        background-color: #deefff !important;
      }
      div:hover {
        font-weight: bolder;
        cursor: pointer;
        span {
          color: #00305f !important;
        }
      }
    }
    #inputGroup-sizing-sm {
      border-radius: 0.35rem 0 0 0.35rem !important;
      height: 25px;
    }
    .search-field,
    .input-group {
      height: 25px;
    }
    .input-group {
      width: 96%;
      margin-left: 8px;
    }
  }
  .medicine-suggestion .nav-item {
    font-size: 12px;
    .nav-link {
      display: block;
      padding: 0.2rem 1rem;
    }
  }
  .user-suggestion,
  .dose-recommendation {
    margin-top: 6px;
    div {
      display: flex;
      justify-content: space-between;
      padding: 2px 8px;
      font-size: 12px;
      .selected-default {
        font-weight: 500;
        color: rgb(230, 74, 74);
        cursor: pointer;
      }
      .instruction {
        max-width: 54%;
      }
    }
    .default {
      color: #00305f;
      background-color: #deefff !important;
    }
  }

  .make-default-btn {
    cursor: pointer !important;
    color: #0d6efd !important;
    &:hover {
      font-weight: bold;
    }
  }

  .dose-recommendation {
    font-size: 13px;
    .schedule-instruction {
      cursor: pointer !important;
      &:hover {
        background-color: #d1d3e2;
      }
    }
  }

  .information {
    margin-top: 6px;
    h2 {
      font-size: 13px;
      margin-bottom: 0.2rem;
    }
    .accordion-header button {
      padding: 5px 0 5px 10px;
    }
    .accordion-collapse {
      padding-left: 20px;
      font-size: 13px;
    }
    .ac-body {
      padding-left: 10px !important;
    }
  }
  .generic-composition {
    div {
      display: flex;
      justify-content: space-between;
      height: 250px;
      padding: 10px 10px;
      font-size: 14px;
      span:first-child {
        width: 20%;
      }
    }
  }
  .formats-type {
    padding: 10px;
    .type {
      span {
        width: 25px;
        text-align: center;
        display: inline-block;
      }
      span:first-child,
      span:last-child {
        cursor: pointer;
      }
    }
  }
  .modal-footer {
    justify-content: center;
    padding: 0.15rem;
    font-size: 14px;
    span {
      color: #000000;
    }
  }
}

.selected-med-view {
  min-height: 200px;
  display: block;
  .droppable {
    position: relative;
    margin-left: 25px;
  }
  .droppable-wife,
  .droppable-husband {
    position: relative;
    margin-left: 25px;
  }
  .selected-medicine-row {
    position: relative;
    &:hover {
      background: #eaf8ff;
    }
  }
  .medicine-schedule-parent {
    padding-left: 0;
    cursor: pointer;
    .medicine-schedule {
      width: 35%;
      display: inline-block;
      // vertical-align: top;
    }
    .medicine-instruction {
      width: 50%;
      display: inline-block;
    }
    .medicine-duration {
      width: 15%;
      display: inline-block;
    }
  }
  .medicine-schedule-parent:hover {
    cursor: pointer;
  }
  .child-medicine {
    padding-left: 45px !important;
  }
}

.selected-item-title {
  margin: 0px 5px 3px 4px;
  font-weight: bold;
}
.selected-medicine-area {
  height: 150px;
  font-size: 15px;
  overflow-y: auto;
  overflow-x: hidden;
}
.header-row div {
  text-align: center !important;
}
.common-input-fields {
  display: flex;
  justify-content: center;
}
.selected-medicine-area {
  .medicine-default {
    top: 230px;
  }
}
.selected-medicine-item {
  background: #eeeeee;
  padding: 3px 5px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  margin-bottom: 5px !important;
  .duration-unit {
    padding-right: 4px;
  }
  .form-control-sm {
    height: calc(1.5em + 0.5rem);
    padding: 0.05rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1;
    border-radius: 0.3rem;
    margin-right: 8px;
  }
  .fa-bars,
  .fa-copy {
    font-size: 12px;
    cursor: pointer;
    color: #0561b7;
  }
  .alter-active {
    color: #0808f3;
  }
  .fa-sync,
  .fa-plus-circle {
    font-size: 13px;
    cursor: pointer;
  }
  .fa-plus-circle {
    font-size: 14px;
    cursor: pointer;
  }
  .alter-inactive {
    color: #ada491;
  }
  .fa-bars {
    padding-left: 7px;
  }
  .fa-copy {
    margin-left: 22px;
    margin-right: 22px;
  }

  .icon-area {
    padding: 3px 6px 3px 4px;
    cursor: pointer;
  }

  .btn-sm {
    background: azure;
    color: #000;
    &:hover {
      font-size: 13px;
      border-color: var(--tab-background) !important;
    }
    box-shadow: RGB(14 30 37 / 12%) 0px 2px 4px 0px,
      RGB(14 30 37 / 32%) 0px 2px 16px 0px;
  }
  .assigned-value {
    background: #00b38c !important;
    font-weight: bold;
    color: #ffffff;
  }
  .ins-btn {
    &:hover {
      font-size: 14px;
      border-color: var(--tab-background) !important;
    }
  }
  .rbt-input-main:focus {
    border: 1px solid #00b38c !important;
  }
  // .assigned-value {
  //   .icon-up {
  //     border-bottom: 9px solid #fff !important;
  //   }
  //   .icon-down {
  //     border-top: 9px solid #fff !important;
  //   }
  //   .arrow-flex {
  //     border-left: 1.5px solid #fff !important;
  //   }
  // }
  .arrow-flex {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 22px;
    padding-right: 1px;
    // border-left: 1.5px solid #9f9d9d;
    font-size: 18px;
    .icon {
      display: inline-block;
      width: 0;
      height: 10px;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
    }
    .icon-up {
      margin-bottom: 1px !important;
      border-bottom: 4px solid black;
    }
    .icon-down {
      margin-top: 1px !important;
      border-top: 4px solid black;
    }
    .fa-caret-up {
      position: absolute;
      right: 0;
      bottom: 8px;
      margin-bottom: 3px !important;
    }
    .fa-caret-down {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
}

.star-active,
.star-inactive {
  font-size: 14px;
  cursor: pointer;
  &:hover {
    font-size: 15px;
  }
}
.star-active {
  color: #e7d75b !important;
}
.star-inactive,
.fa-star {
  color: #9aa8c9;
}
.gem-active {
  color: #e7d75b !important;
  cursor: pointer;
}
.gem-inactive {
  //color: #6781cdeb !important;
  color: rgb(34 88 247 / 92%) !important;
  cursor: pointer;
  font-size: 13px;
  cursor: pointer;
}

.selected-item-row {
  display: flex;
  align-items: center;
  background: #eeeeee;
  padding: 1px 5px;
  border: 1px solid #d5d5d5;
  border-radius: 10px;
  margin-top: 0.25rem !important;
  margin-left: 0px;
  margin-right: 8px;
}

.selected-medicine-edit {
  margin: 0px auto;
  box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
    rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  position: absolute;
  top: 61%;
  left: 15%;
  z-index: 1;
  width: 71%;
}

.selected-drug-histories {
  .medicine-schedule-parent {
    &:hover {
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.move-all-btn {
  font-size: 14px;
  cursor: pointer;
  &:hover {
    font-size: 14px;
    font-weight: bold;
  }
}
.drag-history-item {
  margin: 0px auto;
  box-shadow: rgb(14 30 37 / 12%) 0px 2px 4px 0px,
    rgb(14 30 37 / 32%) 0px 2px 16px 0px;
  position: absolute;
  top: 100%;
  width: 620px !important;
  //margin-left: -60px;
  z-index: 1;
  width: 71%;
  margin-left: 12px;
  padding-bottom: 9px;
  // .unit-btn {
  //   justify-content: flex-end;
  // }
  .btn-sm-customize {
    font-size: 12px !important;
  }
}
.duration-with-unit {
  display: flex;
  justify-content: center;
  margin-right: 3px;
}

.selected-item-area {
  max-height: 68px;
  overflow-y: auto;
}

.selected-item-area {
  max-height: 118px;
  overflow-y: auto;
}

.inv-selected-area {
  min-height: 118px;
}

.selected-hr {
  margin-top: 1.1rem !important;
  margin-bottom: 0.5rem !important;
}

.selected-hr-rx {
  margin-top: 1rem !important;
  margin-bottom: 0.1rem !important;
}

.new-pres-btn {
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: -110px;
  :hover {
    // height: 55px;
    // width: 55px;
    transform: scale(1.5);
    transition: transform 0.3s;
  }
}

.rx-body {
  .history-prescription-item {
    padding: 2px 6px;
    font-weight: bold;
    background-color: #f3f3f3;
    border-radius: 3px;
  }
  .history-item {
    display: inline-block;
  }

  .prescription-item {
    padding: 2px 6px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 3px;
  }

  .prescription-item:hover {
    background-color: #d7d7d7cf;
  }

  .data-item {
    margin-bottom: 0;
  }
  .no-data-item {
    margin-bottom: 30px;
  }

  .data-item-history {
    margin-bottom: 10px;
  }
  .no-data-item-history {
    margin-bottom: 46px;
  }
}

.stage-area {
  .stage {
    text-align: center;
    background-color: #eaeaea;
    height: 115px;
    padding-top: 26%;
    border-radius: 6px;
  }
  button {
    margin-top: 10px;
    width: 99%;
  }
  margin: 9px 15px 0px 2px;
}
#react-select-4-listbox {
  z-index: 99 !important;
}

// unit design area
.inv-parent {
  .inv-unit {
    position: absolute;
    width: 88%;
    background: #ffff;
    z-index: 1;
    div {
      border: 1px solid #d1d3e2;
      padding: 3px 2px 3px 5px;
      cursor: pointer;
      border-radius: 4px;
      font-size: 14px;
    }
  }
}

.parent-rx-sub {
  .rx-sub {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    padding: 4px 4px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: #d1d3e2;
    }
    .schedule {
      width: 27%;
    }
    .instruction {
      width: 50%;
    }
    .duration {
      width: 12%;
    }
    .action {
      text-align: center;
      width: 11%;
    }
    .star-active,
    .star-inactive {
      font-size: 12px !important;
    }
    .star-active:hover,
    .star-inactive:hover,
    .fa-times:hover {
      font-size: 12px;
    }
  }
  .default {
    background-color: #77b977 !important;
    font-weight: bold;
    color: #ffffff;
  }
  .selected {
    //color: #00305f;
    font-weight: bold;
    background-color: #deefff;
  }
}

.parent-rx-sub-search {
  .rx-sub {
    font-size: 11px !important;
    .schedule {
      width: 26%;
    }
    .instruction {
      width: 45%;
    }
    .duration {
      width: 12%;
    }
    .action {
      width: 17%;
    }
    .star-active:hover,
    .star-inactive:hover,
    .fa-times:hover {
      font-size: 12px !important;
    }
  }
}

.medicine-default {
  width: 575px !important;
  margin: 0px auto;
  box-shadow: RGB(14 30 37 / 12%) 0px 2px 4px 0px,
    RGB(14 30 37 / 32%) 0px 2px 16px 0px;
  position: absolute;
  top: 0;
  left: 40%;
  padding: 0 2px;
  z-index: 1;
  width: 71%;
  .card-header {
    padding: 0.2rem 0.5rem;
    justify-content: space-between;
    align-items: center;
    .medicine-name-and-icon {
      height: 22px;
      .medicine-name {
        display: inline-block;
        font-size: 15px;
        font-weight: 700 !important;
      }
      .fa-times-circle {
        float: right;
        padding-top: 12px;
      }
    }
  }
  .generic-name {
    font-size: 12px;
    font-style: italic;
    padding-left: 42px;
    color: #1c4edf;
  }

  .card-footer {
    padding: 0.28rem 1.25rem;
    color: #858796 !important;
    text-align: center;
    font-size: 13px;
    font-style: italic;
    span {
      color: #000000;
    }
  }
  .nav-medicine-default {
    font-size: 13px;
  }
  .card-body {
    padding: 0 2px;
  }

  .modal-body {
    padding: 0 4px;
  }
  .modal-md {
    margin-top: 100px;
  }
  .similar-medicine {
    .list {
      div {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        padding: 0 12px 2px 9px;
        span:first-child {
          width: 48%;
        }
        span:last-child {
          font-style: italic;
          width: 52%;
          text-align: right;
        }
      }
      .selected-similar-medicine {
        color: #00305f;
        font-weight: bolder;
        background-color: #deefff !important;
      }
      div:hover {
        font-weight: bolder;
        cursor: pointer;
        span {
          color: #00305f !important;
        }
      }
    }
    #inputGroup-sizing-sm {
      border-radius: 0.35rem 0 0 0.35rem !important;
      height: 25px;
    }
    .search-field,
    .input-group {
      height: 25px;
    }
    .input-group {
      width: 96%;
      margin-left: 8px;
    }
  }
  .medicine-suggestion .nav-item {
    font-size: 12px;
  }

  // .parent-rx-sub {
  .schedule-instruction-row-new {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    padding: 4px 4px;
    .parent-sugestion {
      background-color: red !important;
      .schedule {
        width: 15%;
      }
      .instruction {
        width: 75%;
      }
      .duration {
        width: 10%;
      }
      .action {
        width: 10%;
      }
    }
    // .schedule-instruction {
    //   padding: 0 5px;
    //   width: 90%;
    //   cursor: pointer;
    //   .schedule {
    //     width: 22%;
    //     display: inline-block;
    //     vertical-align: top;
    //   }
    //   .instruction {
    //     display: inline-block;
    //     width: 68%;
    //   }
    // }
    // .selected {
    //   color: #00305f;
    //   font-weight: bold;
    //   background-color: #deefff;
    // }
    // .selected-default,
    // .make-default-btn {
    //   padding: 0 2px;
    //   width: 20%;
    // }
    // .make-default-btn {
    //   color: #0d6efd !important;
    //   cursor: pointer;
    //   text-align: center;
    //   &:hover {
    //     font-weight: bold;
    //   }
    // }

    // .search-suggestion-rx .subitems-rx .schedule-instruction-rx {
    //   padding: 3px 2px;
    //   font-size: 0.75rem !important;
    // }

    // .schedule-instruction-rx {
    //   font-size: small;
    //   display: flex;
    //   justify-content: space-between;
    //   cursor: pointer;
    // }
    // .schedule-instruction-rx:hover {
    //   background-color: lightgray;
    // }
    //}
  }
  .user-suggestion {
    .hover:hover {
      background-color: #d1d3e2;
    }
    .schedule-instruction-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 3px;
      padding: 4px 4px;
      .schedule-instruction {
        padding: 0 5px;
        width: 90%;
        cursor: pointer;
        .schedule {
          width: 22%;
          display: inline-block;
          vertical-align: top;
        }
        .instruction {
          display: inline-block;
          width: 68%;
        }
      }
      .selected {
        color: #00305f;
        font-weight: bold;
        background-color: #deefff;
      }
      .selected-default,
      .make-default-btn {
        padding: 0 2px;
        width: 20%;
      }
      .make-default-btn {
        color: #0d6efd !important;
        cursor: pointer;
        text-align: center;
        &:hover {
          font-weight: bold;
        }
      }
      .fa-times {
        padding-top: 2px;
        font-size: 14px;
        &:hover {
          font-size: 15px;
        }
      }
    }

    .default {
      background-color: #77b977 !important;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .information {
    margin-top: 6px;
    h2 {
      font-size: 13px;
      margin-bottom: 0.2rem;
    }
    .accordion-header button {
      padding: 5px 0 5px 10px;
    }
    .bangla-english .nav-item {
      font-size: 12px;
      .nav-link {
        display: block;
        padding: 0.2rem 1rem;
      }
    }
    .accordion-body {
      p {
        padding-left: 8px !important;
        margin-bottom: 7px !important;
      }
      .indication-title {
        padding: 3px 7px;
        border-radius: 7px;
        margin-bottom: 3px;
        background-color: #aba8a852;
        font-weight: bold;
        font-size: 13px;
      }
    }
    .accordion-collapse {
      padding-left: 7px;
      font-size: 13px;
    }
  }
  .generic-composition {
    div {
      display: flex;
      justify-content: space-between;
      height: 250px;
      padding: 10px 10px;
      font-size: 14px;
      span:first-child {
        width: 20%;
      }
    }
  }
  .formats-type {
    padding: 10px;
    .type {
      span {
        width: 25px;
        text-align: center;
        display: inline-block;
      }
      span:first-child,
      span:last-child {
        cursor: pointer;
      }
    }
    .format-items {
      font-size: 15px;
      div {
        cursor: pointer !important;
      }
      .is-type-selected {
        color: green;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .type-item {
      font-size: 15px;
    }
  }

  .pdf-prescription {
    .rx-body {
      margin-top: 0.075rem;
    }
    .card {
      border: none !important;
      box-shadow: none !important;
      background-color: #f8f9fc !important;
    }
    .footer-area {
      padding-bottom: 10px !important;
    }
  }
}

.patient_offline {
  height: 10px;
  width: 10px;
  background: #3c6478;
  border-radius: 5px;
  border: 1px solid grey;
}

.patient_online {
  height: 10px;
  width: 10px;
  background: #2ae643;
  border-radius: 5px;
  border: 1px solid grey;
}

/* patient history */
.patient-histories {
  .card-body {
    padding-left: 10px;
    background: #f5f5f5 !important;
  }
  .card-body > div:first-child {
    margin: 10px 15px;
  }
  .patint-history-left {
    background-clip: border-box;
    padding-top: 1rem !important;
    border-radius: 12px;
    background-color: #ffffff;
    .patient-img {
      height: 9rem;
      width: 9rem;
    }
    .patient-phone-number,
    .patient-email .patient-pid {
      font-size: 15px !important;
    }
    .patient-name {
      color: #4e73df;
      font-size: 16px !important;
      margin-top: 7px;
      font-weight: bold;
    }
    .patient-pid {
      font-size: 15px !important;
    }
    .summary-box .col {
      width: 120px;
      height: 70px;
      margin: 4px;
      padding: 3px;
      background-color: #f3f3f3;
      text-align: center;
      border-radius: 8px;
      .item {
        font-size: 14px;
      }
      .value {
        font-size: 15px;
        font-weight: bold;
      }
    }
    // .patient-history .patient-details {
    //   background-clip: border-box;
    //   background-color: #ffffff;
    // }
  }

  .add-new-rx {
    height: 39px;
    border-radius: 6px;
    text-align: center;
    padding-top: 8px;
    background-color: #ffffff !important;
  }
  .prescription-history {
    .accordion-header {
      margin-bottom: 0.2rem !important;
      button {
        border-radius: 8px;
      }
    }
    .prescription-number-circle {
      position: absolute;
      text-align: center;
      width: 39px;
      height: 39px;
      border: 1px solid #1d1d1d;
      border-radius: 50%;
      top: -1px;
      left: -20px;
      background: #ffffff !important;
      opacity: 1;
      div {
        padding-top: 6px;
      }
    }
    .accordion-title-text {
      font-size: 15px !important;
      font-weight: 500 !important;
    }
    .right-edit-icon {
      text-align: end;
      margin-bottom: 3px;
      padding-right: 15px;
      font-size: 15px;
      .create-template-btn {
        padding-right: 6px;
        cursor: pointer;
        color: #df7009;
        &:hover {
          font-weight: 600;
        }
      }
      .pres-edit {
        padding-left: 6px;
        padding-right: 6px;
        text-decoration: none;
        &:hover {
          font-weight: 600;
        }
      }
      .pres-del {
        padding-left: 6px;
        cursor: pointer;
        color: #e74a3b;
        &:hover {
          font-weight: 600;
        }
      }
    }
    .prescription-body {
      border-radius: 12px;
    }
    .prescription-body-left {
      padding-left: 28px;
      width: 35%;
    }
    .prescription-body-right {
      padding-left: 25px;
      width: 60%;
    }
  }
}

.patient-details {
  .patient-img-info {
    margin: 0 auto;
    text-align: center;
  }
  .profile-img {
    height: 6.5rem !important;
    width: 6.5rem !important;
    border-radius: 50% !important;
  }
  .icon-list {
    text-align: end;
    i {
      padding-left: 7px;
      cursor: pointer;
    }
    .fa-plus-circle {
      color: #0561b7;
    }
    .fa-edit {
      color: #149e27;
    }
  }
}

// .tox-tinymce-aux {
//   display: none !important;
// }

// .special-note-view {
//   .only-note {
//     height: 25px;
//     div {
//       // display: flex;
//       // justify-content: space-between;
//     }
//   }
// }

// .sp-note-p p {
//   padding: 0;
//   margin: 0;
// }

.special-note-view {
  .groupName {
    font-weight: 600;
  }
  .special-note p {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.prediction-area {
  height: 452px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 3px 12px 12px 10px;
  .label-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16.5289px;
  }

  .prediction {
    margin-top: 8px;
    .data-area {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 12px;
      border-radius: 8px;
      background-color: #eaeaea;
      margin-bottom: 15px;
      padding-left: 5px;
      padding-right: 5px;
      font-weight: 700;
      height: 68px;
      .point-area {
        display: flex;
        align-items: center;
        font-weight: 900 !important;
        span:first-child {
          color: #014081;
          font-size: 22px;
        }
        span:last-child {
          padding-left: 8px;
        }
      }
      .low {
        color: green;
      }
      .medium {
        color: orangered;
      }
      .high {
        color: red;
      }
    }
  }
}

.multi-range-slider {
  .label {
    font-weight: bold;
  }
}

.eye-glass-modal {
  .eye-glass-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      padding-right: 8px !important;
    }
  }

  table,
  th,
  td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    color: #000000 !important;
    background: #f8f8f8;
    text-align: center;
  }

  .eye-glass-title {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    span:first-child {
      color: #0561b7;
    }
  }

  .r-va-btn {
    top: 41px;
    right: 0;
  }

  .l-va-btn {
    top: 95px;
    right: 0;
  }

  .rcyl {
    right: 110px;
  }

  .raxis {
    right: 10px;
  }

  .lsph {
    top: 131px !important;
  }

  .lcyl {
    top: 131px !important;
    right: 110px;
  }

  .laxis {
    top: 131px !important;
    right: 10px;
  }

  .add-slider {
    top: 41px !important;
  }

  .glass-slider-area {
    position: absolute;
    z-index: 1;
    height: 65px;
    width: 70%;
    top: 80px;
    padding: 20px 25px;
    border-radius: 6px;
    background: #ffffff;
    border: 0.5px solid #d9cece;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  }

  .serum-slider {
    position: static !important;
    padding: 12px 15px 0 10px;
    width: 100%;
  }
}

.view-eye-glass {
  width: 100%;
  font-size: 15px;
  padding-left: 7px;
  position: relative;

  table,
  th,
  td {
    border: 1px solid black !important;
    border-collapse: collapse !important;
    color: #000000 !important;
    background: #f8f8f8;
    text-align: center;
    padding: 0.4rem !important;
  }
  .table {
    width: 80% !important;
    margin-bottom: 0.4rem;
  }
  .eyeglass-del-icon {
    position: absolute;
    right: 0;
    top: 45px;
  }
  span {
    font-style: italic;
  }
}

.add-more-lg {
  display: none;
}

/*----START TABLE CSS----*/
table,
caption,
tbody,
tfoot,
thead,
tr,
th {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 85%;
  vertical-align: baseline;
  background: transparent;
  line-height: 0.75rem;
}

table,
caption,
tbody,
tfoot,
thead,
tr,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 96%;
  vertical-align: baseline;
  background: transparent;
  line-height: 0.75rem;
}

.appointment-card-body {
  background-color: #f5f5f5;
}

.table {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

tr {
  border: 0px;
  background-color: white;
}

thead tr {
  border: 0px;
  border-radius: 8px;
  background-color: transparent;
}

td,
th {
  border-style: hidden !important;
}

tr td:first-child {
  border-top-left-radius: 10px;
}

tr td:last-child {
  border-top-right-radius: 10px;
}

tr td:first-child {
  border-bottom-left-radius: 10px;
}

tr td:last-child {
  border-bottom-right-radius: 10px;
}

.table td,
.table th {
  padding: 0.6rem;
}

.table td,
.table th {
  vertical-align: middle;
}

.payment-success {
  color: green;
}

.payment-pending {
  color: #36b9cc;
}

.payment-unpaid {
  color: red;
}

.payment-cancelled {
  color: orange;
}

.appointment-status {
  color: white;
  padding: 4px 30px;
  border-radius: 3px;
}
/**** End Table CSS **/

.online-yes {
  color: #0d6efd;
}

.move-to-rx {
  cursor: pointer;
  &:hover {
    font-weight: bold;
    font-size: 16px;
  }
}

.video-switch {
  height: 27px;
  display: inline-flex;
  #toggle {
    display: none;
  }

  label {
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-left: 30px;
  }

  label::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  #toggle:checked + label {
    background-color: #2196f3;
  }

  #toggle:checked + label::before {
    transform: translateX(20px);
  }
}

.patient-list {
  tbody tr {
    border-top: 0px;
    cursor: pointer;
    height: 69px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #000000d9;
  }
  .patient-name-img {
    display: flex;
    justify-content: left;
    align-items: center;
    img {
      height: 48px;
      width: 48px;
      border: 1px solid #eff3f3;
      margin-right: 0.4rem;
    }
    .avatar-parent {
      margin-right: 0.4rem;
      .avatar-child {
        width: 48px;
        height: 48px;
        font-size: 14px;
        font-weight: 600;
        padding-top: 17px;
        margin-left: 0;
      }
    }
    .name {
      padding-top: 5px;
      font-family: Roboto;
      color: #000000;
      font-weight: 600;
      padding-bottom: 0.4rem;
    }
    .gender-age {
      font-size: 13px;
      color: #646464d9;
    }
  }
  .consult-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    margin-left: auto;
    margin-right: 22px;
    border-radius: 8px;
    span {
      padding-left: 10px;
      padding-right: 13px;
    }
    i {
      font-size: 18px;
    }
  }
}

.appointment-filter {
  div {
    margin-top: 10px;
    margin-left: 13px;
  }
}
.plus-active {
  color: #099f09;
  cursor: pointer;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1208px) {
  .template-search {
    width: 250px;
  }
  .right-side-pres > div:not(:last-child) {
    margin-right: 10px !important;
  }
  .patient-call {
    margin-right: 2px;
    text-decoration: none;
    color: rgb(20, 158, 39);
  }
  .take-note,
  .print-preview {
    padding-right: 7px;
    font-size: 13px;
  }
  .selected-item-row {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .supported-by {
    margin-left: 1rem !important;
  }
  .patient-section {
    .name {
      width: 210px;
    }
    .phone-number {
      width: 140px;
    }
    label {
      font-size: 14px !important;
    }
    .add-more-info {
      font-size: 12px;
      padding: 2px 4px;
    }
  }
  .footer-area {
    bottom: 20px;
    width: 88%;
  }
  .medicine-schedule-parent {
    font-size: 15px !important;
  }
  .medicine-schedule-row {
    font-size: 15px !important;
  }
  .selected-medicine-row {
    .selected-medicine-item {
      width: 88%;
      left: 8%;
      top: 50px;
    }
    .selected-medicine-edit {
      font-size: 15px;
    }
  }
  .medicine-default {
    width: 500px !important;
    top: 120px;
    left: auto;
    .similar-medicine {
      .list {
        div {
          font-size: 12px;
        }
      }
    }
    .medicine-suggestion .nav-item {
      font-size: 12px;
    }
    .user-suggestion,
    .dose-recommendation {
      div {
        font-size: 12px;
      }
    }
    .dose-recommendation {
      font-size: 12px;
    }

    .information {
      h2 {
        font-size: 12px;
      }
      .accordion-collapse {
        font-size: 12px;
      }
    }
    .generic-composition {
      div {
        font-size: 12px;
      }
    }
    .modal-footer {
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .doctor-name,
  .doctor-speciality,
  .doctor-organization {
    font-size: 14px;
  }
  .modal-title {
    font-size: 0.8rem;
  }
  .gyne-tab {
    font-size: 13px !important;
  }
  .template-search {
    width: 230px;
  }
  .selected-item-row {
    font-size: 13px;
  }
  .medicine-schedule-row {
    font-size: 14px !important;
  }
  .medicine-schedule-parent {
    font-size: 14px !important;
  }
  .selected-medicine-item {
    font-size: 14px;
    .fa-bars {
      padding-left: 7px;
    }
    .fa-copy {
      margin-left: 13px;
      margin-right: 13px;
    }
    .medicine-default {
      width: 420px !important;
      top: 120px;
      left: auto;
      .similar-medicine {
        .list {
          div {
            font-size: 12px;
          }
        }
      }
      .medicine-suggestion .nav-item {
        font-size: 12px;
      }
      .user-suggestion,
      .dose-recommendation {
        div {
          font-size: 12px;
        }
      }
      .dose-recommendation {
        font-size: 12px;
      }

      .information {
        h2 {
          font-size: 12px;
        }
        .accordion-collapse {
          font-size: 12px;
        }
      }
      .generic-composition {
        div {
          font-size: 12px;
        }
      }
      .modal-footer {
        font-size: 12px;
      }
    }
  }
  .follow-up-form {
    font-size: 13px;
    .form-label {
      font-weight: normal !important;
    }
  }
  .unit-btn {
    .btn-sm-customize {
      font-size: 12px;
      line-height: 1rem !important;
      min-width: 20px;
      height: 20px;
      padding: 0.1rem 0.2rem !important;
    }
    .form-control-sm {
      height: calc(1em + 0.5rem);
    }
  }
  .patient-info .custom-tab .nav-link {
    padding: 0.1rem 0.5rem;
    font-size: 13px !important;
  }
  .eye-glass-modal {
    .form-check-label {
      font-size: 13px;
    }
    table,
    th,
    td {
      font-size: 13px;
    }
    .eye-glass-bottom {
      font-size: 13px;
    }
    .eye-glass-remarks {
      font-size: 13px;
    }
  }
  .right-side-pres > div:not(:last-child) {
    margin-right: 12px !important;
  }
  .header-sticky-second .left-side-pres {
    width: 400px;
  }
  .patient-call {
    margin-right: 2px;
    text-decoration: none;
    color: rgb(20, 158, 39);
  }
  .take-note {
    display: none;
    padding-right: 7px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 820px) {
  .logo-img {
    height: 50px;
    width: 200px;
  }
  .supported-by {
    display: none;
  }
  .doctor-name,
  .doctor-speciality,
  .doctor-organization {
    font-size: 14px;
  }
  .modal-title {
    font-size: 0.7rem;
  }
  .patient-section {
    justify-content: center;
    .form-inline:not(:last-child) {
      display: none;
    }
    .date-label,
    .date-field {
      display: none;
    }
    .add-more-sm {
      display: none;
    }
    .add-more-lg {
      display: inline-block;
    }
  }
  .footer-area {
    bottom: 20px;
    width: 85%;
  }
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 91%;
    vertical-align: baseline;
    background: transparent;
    line-height: 0.75rem;
  }
  .selected-medicine-row {
    .selected-medicine-item {
      width: 88%;
      .custom-tab {
        display: block;
        .nav-link {
          padding: 0.32rem 1rem;
        }
      }
      .medicine-default {
        width: 400px !important;
        top: 120px;
        left: auto;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .logo-img {
    height: 50px;
    width: 200px;
  }
  .supported-by {
    display: none;
  }
  .doctor-name,
  .doctor-speciality,
  .doctor-organization {
    font-size: 13px;
  }
  .modal-title {
    font-size: 0.7rem;
  }
  /* Styles specific to mobile devices */
  .content-center {
    display: flex;
    justify-content: center;
  }
  // for responsive
  .hide-in-print {
    display: none;
  }
  .patient-section {
    justify-content: center;
    .form-inline:not(:last-child) {
      display: none;
    }
    .date-label,
    .date-field {
      display: none;
    }
    .add-more-sm {
      display: none;
    }
    .add-more-lg {
      display: inline-block;
    }
  }

  .selected-item-title {
    font-size: 14px;
  }
  .selected-item-row {
    font-size: 13px;
    .name {
      padding-left: 0;
      padding-right: 0;
    }
    .responsive {
      margin-bottom: 5px;
      padding-right: 0;
    }
  }
  .complains-area .chief-complain-item {
    font-size: 13px;
    padding: 1px 8px;
  }
  .custom-tab {
    font-size: 14px;
  }
  .patient-info .custom-tab .nav-link {
    padding: 0.1rem 0.3rem;
    font-size: 13px !important;
  }
  .search-parent .Type__placeholder {
    font-size: 12px !important;
  }
  .new-rx-btn {
    font-size: 12px !important;
    right: 59px !important;
    top: 11px !important;
  }
  .search-icon {
    top: 11px;
    left: 25px;
  }
  .customize-btn {
    font-size: 11px;
  }
  .medicine-schedule-input-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 30px;
    padding: 0.15rem 0.15rem;
    font-size: 11px;
    height: 20px;
    line-height: 0.8rem !important;
  }
  .rx-type-btns > button {
    padding: 0.01rem 0.3rem !important;
    border-radius: 5px !important;
    line-height: 0.5rem !important;
    font-size: 11px !important;
    height: 20px;
  }
  .rx-type-btns .fa-times-circle {
    margin-left: 0.9rem;
  }
  .selected-medicine-item {
    font-size: 13px !important;
    .fa-bars {
      padding-left: 4px;
    }
    .fa-copy {
      margin-left: 8px;
      margin-right: 8px;
    }
    .fa-plus {
      margin-left: 0.5rem !important;
    }
    .medicine-default {
      width: 380px !important;
      top: 120px;
      left: auto;
    }
  }

  .unit-btn {
    .btn-sm-customize {
      font-size: 11px;
    }
  }
  .follow-up-form {
    font-size: 12px;
    .form-label {
      font-weight: normal !important;
    }
  }
  .responsive {
    margin-bottom: 5px;
  }
  .eye-glass-modal {
    .form-check-label {
      font-size: 12px;
    }
    table,
    th,
    td {
      font-size: 12px;
    }
    .eye-glass-bottom {
      font-size: 12px;
    }
    .eye-glass-remarks {
      font-size: 12px;
    }
  }

  .footer-area {
    bottom: 20px;
    width: 85%;
  }

  /* Styles specific to mobile devices */
  .ordering-1 {
    order: 2;
    padding-top: 5px;
  }
  .ordering-2 {
    order: 1;
  }

  #appointmentTable {
    width: 100%;
  }

  #appointmentTable thead {
    display: none;
  }

  #appointmentTable tr td {
    display: grid;
    grid-template-columns: 17ch auto;

    /* border: 1px solid gray; */
    /* background-color: #eee; */
  }

  #appointmentTable tr td::before {
    content: attr(data-cell) ':';
    font-weight: 700;
    text-transform: capitalize;
  }

  .appointment-status {
    width: 100px;
  }

  #sidebarToggleTop,
  #searchDropdown {
    display: none;
  }

  .appointment-status {
    padding: 6px 10px;
  }

  .table td {
    padding: 0.25rem 0.6rem !important;
  }
}

.action-col {
  text-align: center;
  transition: background-color 0.3s;
  &:hover {
    background-color: #d7efe6;
  }
}

.new-app {
  &:hover {
    font-size: 14px;
  }
}

.search-parent > div > .Type__control {
  padding-left: 30px;
}

@media only screen and (max-width: 600px) {
  .hide-show-bar {
    margin-right: 0px;
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .mt-2-mobile {
    margin-top: 0.5rem;
  }

  .modal-title {
    font-size: 0.7rem;
  }
}

.hide-show-bar {
  margin-right: 15px;
  margin-left: 10px;
  padding-top: 5px;
  font-size: 20px;
  cursor: pointer;
}

#appointmentTable tbody tr:nth-child(odd) {
  background-color: rgb(232, 230, 230);
}
#appointmentTable tbody tr:nth-child(even) {
  background-color: rgb(232, 230, 230);
}
#appointmentTable tbody tr:hover {
  background-color: rgb(218, 215, 215);
}

.appointment_button {
  width: 10rem;
}
.appointment_button_date {
  width: 9rem;
}

.searchbar-expand-sign {
  position: absolute;
  right: 25px;
  top: 6px;
  border-left: 1px solid hsl(0, 0%, 80%);
  padding-left: 8px;
  .expand-icon {
    fill: hsl(0, 0%, 80%);
  }
}

.search-parent {
  .rbt-input-main {
    padding-left: 2.3rem;
    padding-right: 7.7rem;
  }
  .rbt-menu {
    border: 1px solid #d5d5d5;
  }
  .dropdown-item {
    white-space: normal;
  }
  .fa-angle-right {
    color: #00b38c;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 18px;
    border-left: 1px solid #d9d9d9;
  }
  .selected-opt .fa-angle-right {
    border-left: 1px solid #ffffff !important;
  }
  a {
    //background: #b9e5db;
    border-bottom: 1px solid #e6f3ed;
    padding: 0.25rem 0.15rem 0.25rem 1rem !important;
  }
  .focus {
    border: 2px solid #2684ff;
  }
}

.search-parent a:has(.selected-opt) {
  background-color: #b9e5db;
}

.duration-arrow-parent {
  position: relative;
  .form-control[type='number']::-webkit-inner-spin-button,
  .form-control[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .form-control[type='number'] {
    -moz-appearance: textfield;
  }
  .duration-arrow {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 99;
    top: 4px;
    justify-content: center;
    align-items: center;
    height: 22px;
    border-left: 1.5px solid #9f9d9d;
    font-size: 18px;
    .icon {
      cursor: pointer;
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
    .icon-up {
      margin-bottom: 1px !important;
      border-bottom: 9px solid black;
    }
    .icon-down {
      margin-top: 1px !important;
      border-top: 9px solid black;
    }
    .fa-caret-up {
      position: absolute;
      right: 0;
      bottom: 8px;
      margin-bottom: 3px !important;
    }
    .fa-caret-down {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }
  .history {
    left: 72px;
  }
  .chief-comp {
    left: 78px;
  }
}

.fraction {
  display: inline-flex;
  align-items: normal;
  vertical-align: 4px;
  .numerator {
    vertical-align: top;
    font-size: 13px;
  }

  .separator {
    padding-top: 4px;
    font-size: 13px;
  }

  .denominator {
    vertical-align: bottom;
    font-size: 13px;
    padding-top: 7px;
  }
}

.mixed-fraction {
  display: inline-flex;
  align-items: normal;
  font-size: 16px !important;

  // .whole-number {
  //   margin-right: 0.2em;
  //   font-size: 1.2rem;
  // }

  .wfraction {
    display: inline-flex;
    align-items: normal;
    font-size: 1.2rem;
  }

  .wnumerator {
    vertical-align: top;
    font-size: 13px;
    margin-right: 0.05em;
  }

  .wseparator {
    font-size: 13px;
    padding-top: 4px;
  }

  .wdenominator {
    vertical-align: bottom;
    font-size: 13px;
    margin-left: 0.05em;
    padding-top: 7px;
  }
}
.sms-block-container {
  display: flex;
  justify-content: space-between;
  justify-items: center;

  .statistics-block-sms {
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    border-radius: 0.4rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 30px 0 rgba(0, 0, 0, 0.1);

    .title {
      font-size: 1rem;
      display: block;
      padding-top: 1rem;
    }
  }
  .sms-type-circle {
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;

    .icon {
      color: white;
      padding-top: 2rem;
    }
  }
}
.sms-patient-select {
  width: 100%;
  display: block;
  height: 2rem;
  border: 1px solid lightgrey;
  margin-bottom: 1rem;
  padding: 3px 5px;
  background-color: white;
}
.sms-patient-summary {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin-top: 3rem;
  padding: 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 30px 0 rgba(0, 0, 0, 0.1);
}
